export const getHalfwayPage = (index, currentPage, numberPages) => {
  const forwardOrBackward = index > 2 || currentPage <= 2;
  let pageToNavigateTo;
  if (forwardOrBackward) {
    pageToNavigateTo = currentPage + (numberPages - 1 - currentPage) / 2;
  } else {
    pageToNavigateTo = currentPage - (currentPage - 2) / 2;
  }
  return Math.floor(pageToNavigateTo);
};
