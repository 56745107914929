import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';
import SEO from '../components/SEO';
import { AbsoluteHeaderStyles, AdviceGrid } from '../components/AdviceGrid';
import Pagination from '../components/Pagination';
import { PageStyles, RightPanelStyles, StandardGridStyles } from '.';
import { AdviceCategories } from '../components/AdviceCategories';
import { CreatePostInputLink } from '../components/CreatePostInputLink';
import { PostCategoryHeaderStyles } from '../templates/PostCategory';
import { AdviceSortByHeader } from '../components/AdviceSortByHeader';
import { GenericHeader } from '../components/GenericHeader';
import { ComediansTrendingList } from '../components/ComediansTrendingList';
import { ComediansSearchBar } from '../components/ComediansSearchBar';

export default function PostsPage({ data, pageContext }) {
  const { adviceContents } = data;
  const { length } = adviceContents;
  const header =
    pageContext && pageContext.currentPage > 1
      ? `Posts - Page ${pageContext.currentPage || 1}`
      : 'Posts';
  return (
    <>
      <SEO
        title={
          pageContext.currentPage !== 1
            ? `Posts | Page ${pageContext.currentPage || 1}`
            : 'Posts'
        }
      />
      <PageStyles>
        <StandardGridStyles>
          <Box>
            <PostCategoryHeaderStyles>
              <AbsoluteHeaderStyles>
                <AdviceSortByHeader noUnderline />
              </AbsoluteHeaderStyles>
            </PostCategoryHeaderStyles>
            <GenericHeader header={header} />
            <AdviceGrid
              allAdvice={adviceContents.nodes}
              totalLength={length}
              standalonePage
            />
            <Pagination
              pageSize={parseInt(process.env.GATSBY_ADVICE_PER_PAGE)}
              totalCount={adviceContents.totalCount}
              currentPage={pageContext.currentPage || 1}
              skip={pageContext.skip}
              base="/posts"
            />
          </Box>
          <RightPanelStyles>
            <ComediansSearchBar noLabel />
            <Box mt="10px">
              <AdviceCategories />
            </Box>
            <Box mt="10px">
              <CreatePostInputLink />
            </Box>
            <Box mt="10px">
              <ComediansTrendingList />
            </Box>
          </RightPanelStyles>
        </StandardGridStyles>
      </PageStyles>
    </>
  );
}

export const query = graphql`
  query($skip: Int = 0, $limit: Int = 20) {
    adviceContents: allSanityAdviceContent(
      limit: $limit
      skip: $skip
      sort: { fields: _createdAt, order: DESC }
    ) {
      totalCount
      nodes {
        id
        title
        slug {
          current
        }
        content
        sourceLink
        sourceTitle
        comedian {
          id
          firstName
          lastName
          slug {
            current
          }
          avatar {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        categories {
          id
          slug {
            current
          }
          category
        }
        _createdAt
      }
    }
  }
`;
