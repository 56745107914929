import React from 'react';
import { Link } from 'gatsby';
import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { getPagesArr } from '../utils/getPagesArr';
import { generateId } from '../utils/generateId';
import { getHalfwayPage } from '../utils/getHalfwayPage';

export const PaginationStyles = styled.div`
  margin: 1rem 0 0.5rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  .pages-flex {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-items: center;
    justify-content: center;
    text-align: center;
    & > * {
      padding: 0 0.8rem;
      text-decoration: none;
      font-weight: 300;
      &.current,
      &:hover {
        font-weight: 700;
      }
      &[disabled] {
        pointer-events: none;
        color: #b8c9da;
      }
    }
    .current,
    .page-number {
      font-size: 1.3rem;
      &:hover {
        font-weight: 700;
      }
    }
    .current {
      font-weight: 700;
    }
  }
  .button {
    padding: 0 0.5rem;
    text-align: center;
    font-size: 1.3rem;
    border-radius: 3px;
    &:hover {
      font-weight: 700;
    }
    &[disabled] {
      pointer-events: none;
      color: #b8c9da;
    }
  }
  .arrow {
    @media (max-width: 414px) {
      display: none;
    }
  }
`;

export default function Pagination({
  pageSize,
  totalCount,
  currentPage,
  skip,
  base,
}) {
  // make some variables
  const totalPages = Math.ceil(totalCount / pageSize);
  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const hasNextPage = nextPage <= totalPages;
  const hasPrevPage = prevPage >= 1;
  const pagesArr = getPagesArr(totalPages, currentPage);
  return (
    <PaginationStyles>
      <Link
        className="button"
        title="Prev Page"
        disabled={!hasPrevPage}
        to={`${base}/${prevPage}`}
      >
        <span className="arrow">←</span> Prev
      </Link>
      <Box className="pages-flex">
        {pagesArr.map((p, i) => (
          <React.Fragment key={generateId()}>
            {p !== '...' && (
              <Link
                className={
                  currentPage === p ? 'current page-number' : 'page-number'
                }
                to={`${base}/${p > 0 ? p : 1}`}
              >
                {p}
              </Link>
            )}
            {p === '...' && (
              <Link
                className="page-number"
                to={`${base}/${getHalfwayPage(i, currentPage, totalPages)}`}
              >
                {p}
              </Link>
            )}
          </React.Fragment>
        ))}
      </Box>
      <Link
        className="button"
        title="Next Page"
        disabled={!hasNextPage}
        to={`${base}/${nextPage}`}
      >
        Next <span className="arrow">→</span>
      </Link>
    </PaginationStyles>
  );
}
