/* eslint-disable no-plusplus */
export const getPagesArr = (pages, page) => {
  let pagesArr = [];
  for (let i = 1; i <= pages; i++) {
    pagesArr.push(i);
  }
  if (pagesArr.length > 7) {
    const firstTwo = pagesArr.slice(0, 2);
    const lastTwo = pagesArr.slice(pagesArr.length - 2, pagesArr.length);
    let middle = page > 2 && page < lastTwo[0] ? ['...', page, '...'] : '...';
    if (page - 1 === firstTwo[1]) {
      middle = middle.slice(1, 3);
    }
    if (page + 1 === lastTwo[0]) {
      middle = middle.slice(0, 2);
    }

    pagesArr = firstTwo.concat(middle).concat(lastTwo);
  }
  return pagesArr;
};
